

.icon__copy-file-info-collapse {
    position: relative !important;
    display: inline !important;
    width: 24px !important;
    height: 24px !important;
    transition: none !important;
    transform: none !important;
    margin-right: 10px !important;
}


.span__font-monospace {
    font-family: monospace;
}


.card__tile__related-actor {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
}


.card__body-cve-references {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}


.div__cve-references-row {
    padding: 15px 25px 15px 20px;
}


.card__body-hash-relationship {
    max-height: 500px;
    overflow-y: scroll;
}


.card__body-communicating-files {
    max-height: 500px;
    overflow-y: scroll;
}


.card__body-content-timeline {
    max-height: 500px;
    overflow-y: scroll;
}

.card__body-file-details {
    max-height: 600px;
    overflow-y: scroll;
}


.p__cve-public-exploit {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
}
