.modules_list {
    background: #2a2a31;
    margin: 2px 10px;
    padding-top: 10px;
    color: #fff;
    .actions{
        cursor: pointer;
        svg{
            margin-right: 10px;
            margin-top: -5px;
        }
    }
    .modules_list_inner {
        margin: 10px 35px;
    }
}

.menu_item_list{
    color: #fff;
    .menu_item{
        background: #2a2a31;
        padding: 10px;
        margin-bottom: 10px;
        text-transform: capitalize;
        list-style: none;
        cursor: pointer;
    }
}