.ticketWrapper, .ticketDetailWrapper {

  .chat__form {
    textarea {
      min-height: 50px !important;
    }
  }

  .attachmentLink {
    text-decoration: underline;
    color: #fff;
  }

  .attachmentLink:hover {
    cursor: pointer;
  }

  .ticket-badge__status_ptions {
    span {
      margin-right: 5px;
    }
  }

  .card-header__ticket {
    @extend .card-header__events;

    padding: 0;
  }

  .chat__dialog {
    width: auto !important;
  }

  .chart_data_info {
    cursor: auto;
  }

  .chat__scroll {
    max-height: 400px;
    overflow: auto;
  }

  .descriptionInfo {
    max-width: calc(100% - 52px);
    color: #fff;
    text-transform: none;
    margin-top: 30px;
  }

  .chat__bubble {
    &.right {
      float: right;
      clear: both;

      .chat__bubble-message-wrap {
        border-radius: 40px 0 20px 40px;
        padding-left: 40px;
      }
    }

    &.left {
      float: left;
      clear: both;
    }
  }
}

.ticket-empty__container {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;

  .row {
    margin: 0;
  }

  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media screen and (min-width: 1440px) {
    width: 50% !important;
  }

  @media screen and (min-width: 768px) {
    width: 80% !important;
  }

}

.ticket-empty__col {
  display: flex;
  justify-content: center;
}

.ticket-empty__h2-title {
  margin: 30px 0;
}

.ticket-empty__h4-desc {
  margin: 20px 0 30px 0;
  text-align: center;
}

.ticket-empty__img-icon {
  width: 340px;
  height: 340px;
}

.ticket-empty-empty__col-submit {
  position: relative;
  max-width: 200px;
  display: flex;
  justify-content: center;
}

.ticket-empty__img-arrow {
  position: absolute;
  width: 20px;
  height: 40px;

  top: -20px;
  right: 0;
}
