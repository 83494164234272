@mixin card-body-shadow {
  box-shadow: rgba(#1b1f26, 0.38) 0px 0px 2px 0px, rgba(#161616, 0.38) 0px 10px 18px -4px;
}

.card {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding-bottom: 30px;

  &.card--not-full-height {
    height: auto;
  }

  .cursor {
    cursor: pointer;
  }
}

.ltr-support .slick-slider .slick-slide {
  padding-right: 0px !important;
}

.carouselCard {
  width: 96%;
  //padding: 45px;
  height: 100%;
  //border-radius: 8px;
  margin: auto;
  min-height: 285px;

  //@include themify($themes) {
  //  background-color: themed('colorBackground');
  //}

  //.card-body {
  //  box-shadow: none;
  //}
}

.card--related-actors {
  .card-body {
    box-shadow: none;
  }
}

.card-body {
  height: 100%;
  border-radius: 5px;
  @include card-body-shadow;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  @include directify($directions) {
    text-align: directed('left');
  }

  h5.card__title-center {
    text-align: center;
  }

  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  .subhead__events {
    @extend .subhead;
    font-size: 14px !important;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.three-dots {
  color: #40e5e8 !important;
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.card__with_background_icon {
  overflow: hidden;

  svg {
    position: absolute;
    height: 120px;
    width: 120px;
    top: calc(50% - 60px);
    opacity: 0.3;

    @include directify($directions) {
      #{directed('right')}: -23px;
    }

    &.editIcon {
      position: relative;
      width: 24px;
      height: 24px;
      right: unset;
      left: unset;
      top: unset;
      margin: 10px 0;
      opacity: 1;
    }

    color: #ffffff;
  }

  .card__with_background_icon-indicators-label {
    float: right;
    margin-top: 5px;
    position: relative;
  }

}

.card__with_background_icon_decoration {
  @extend .card__with_background_icon;

  border-top: solid 1pt #40e5e8;

  a:hover {
    color: #40e5e8;
  }

  svg {
    fill: #579da9;
  }

  .card__with_background_icon-indicators-label {
    color: #579da9;
  }
}

.card-header__events {
  border-radius: 5px;
  padding: 25px;
  cursor: pointer;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .ticektRightIcon {
    fill: #fff;
    position: absolute;
    top: calc(50% - 32px);
    right: 25px;
  }

  &.no_cursor {
    cursor: auto;
  }
}

.card-carousel-button {
  background-color: #3A888B !important;
  color: white !important;
  border: none;
  float: right;
  margin: auto;

  &:hover {

    background-color: #8AA1A2 !important;
    color: black !important;

  }
}

//.card-carousel-header__events {
//  border-radius: 5px;
//  padding: 60px;
//
//  @include themify($themes) {
//    background-color: themed('colorBackground');
//  }
//}

#arrow:hover path {
  fill: #40e5e8 !important;
}

#arrow:hover {
  cursor: pointer;
}


// .card-header__events:hover {
//   background: #38373f;
// }

.card-body__tlp-white {
  border-radius: 5px;
  border-left: 4px solid #ffffff;
  @include card-body-shadow;
}

.card-body__tlp-green {
  border-radius: 5px;
  border-left: 4px solid #b8e986;
  @include card-body-shadow;
}

.card-body__tlp-amber {
  border-radius: 5px;
  border-left: 4px solid #f6da6e;
  @include card-body-shadow;
}

.card-body__tlp-red {
  border-radius: 5px;
  border-left: 4px solid #ff4861;
  @include card-body-shadow;
}

.card__title__events {
  @extend .card__title;
  margin-bottom: 20px;

  .takeDownEditOptions {
    text-transform: none;

    .form__form-group {
      margin-bottom: 0;
    }
  }
}

.card-body__priority-low {
  border-radius: 5px;
  border-left: 4px solid #b8e986;
  @include card-body-shadow;
}

.card-body__priority-medium {
  border-radius: 5px;
  border-left: 4px solid #f6da6e;
  @include card-body-shadow;
}

.card-body__priority-high {
  border-radius: 5px;
  border-left: 4px solid #ff4861;
  @include card-body-shadow;
}

.card--arrow-carousel {
  width: 70px;
  height: 95px;
  color: white;
  font-size: 28px;
  text-align: center;

  &-dx {
    @extend .card--arrow-carousel;
    float: right;
  }

  &-sx {
    @extend .card--arrow-carousel;
    bottom: 0;
    position: absolute;

    svg {
      transform: rotateY(180deg);
    }
  }

  .card-body {
    cursor: pointer;
    padding: 0;
    border-radius: 25px;
  }

  .card-body:hover {
    #arrow path {
      fill: #40e5e8;
    }
  }

  svg {
    height: 25px;
    width: 25px;
    margin-top: 20px;
  }
}

.card--histogram-timeline {
  .card-body {
    padding-bottom: 5px;
  }

  .card__title {
    margin-bottom: 5px;
  }
}

.actors-wrapper, .malware-family-wrapper {
  .card-body {
    display: flex;
    flex-direction: column;
    height: 100%;

    .description {
      margin-bottom: 44px;
    }

    .actionBar {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;

      .mdi-icon {
        font-size: 24px;
        fill: #FFFFFF;
        margin: 0
      }

    }
  }
}

.actors-export-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  .export-choice {
    color: white;
    margin: 0 4px;
    padding: 0 2px;
    cursor: pointer;
  }

  .export-choice:hover, export-choice:focus {
    text-decoration: underline;
  }

}

.cve-results-wrapper {
  padding-bottom: 50px;

  .card {
    padding-bottom: 0 !important;
    padding-top: 30px;

    .card-body {
      padding: 1rem;
      cursor: pointer;
      border-bottom: 1pt solid;

      &:hover {
        border-bottom: 1pt solid #4ce1b6;
        background-color: #38373f;
      }
    }
  }
}



