.drawer__detail {
  position: fixed;
  top: 0;
  z-index: 102;

  @include directify($directions) {
    #{directed('right')}: 0px;
  }

}

@keyframes iconOpacity {

  from {
    opacity: 1
  }
  50% {
    opacity: 0.3
  }
  to {
    opacity: 1
  }
}

.drawer__detail .drawer__detail__wrap .table td {
  padding: 7px 0 7px 0;
}

.drawer__detail .drawer__detail__wrap .table th {
  padding: 7px 0 7px 0;
}

.drawer__detail__wrap {
  height: 100vh;
  width: 500px;
  overflow-y: auto;
  box-shadow: none;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.3s;
  position: fixed;
  top: 0;
  z-index: 102;

  &.drawer__detail__wrap--open {
    transform: translateX(0);
    box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  }

  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.drawer__detail__title-wrap {
  padding-bottom: 20px;
  position: relative;

  @include directify($directions) {
    text-align: directed('left');
  }

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.drawer__detail__close-btn {
  position: absolute;
  top: 0;
  background: transparent;
  border: none;
  cursor: pointer;

  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  svg {
    fill: $color-additional;
    width: 14px;
    height: 14px;
    transition: 0.3s;
  }

  &:hover {

    svg {
      fill: $color-accent;
    }
  }
}