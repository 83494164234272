.gene-detail-container {
    display: flex;
}

.gene-detail-icon {
    padding: 18px 7px 18px 18px;
    display: flex;
    box-sizing: inherit;
}

.icon__collapse__gene-detail:hover {
    cursor: pointer;
}

.gene-detail-content {
    text-align: left;
    padding: 10px 40px 10px 20px;
    display: block;
    width: 100% !important;
}