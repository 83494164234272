.node__basic > circle {
    fill: #F3F3FF;
    stroke: #2593B8;
    stroke-width: 2px;
    r:8;
}

.node__basic > g > text {
    x: 0 !important;
    y: 35 !important;
}

.node__basic text {
    fill: #F3F3FF;
    stroke: #F3F3FF;
    stroke-width: 0.2px;
}

.link__basic  {
    fill: none !important;
    stroke: #2593B8 !important;
    stroke-width: 1.5px;
    text: red  !important;
}