.file-download {
  cursor: pointer;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 0;
  margin: 0 auto;
}

.file-download.disabled {
  opacity: 0.5;
  pointer-events: none;
}
