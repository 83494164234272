.div__tags-ml {
  margin-left: 10px;
}

.rules__card-body {
  margin: 10px 0 10px 15px;
}

.rules__col-rule-body {
  background-color: #333246 !important;
  padding: 20px;
}


.rules__nav_content {
  width: 200px !important;
  padding-left: 0;
  margin-top: 15px;
}

.rules__nav-title {
  margin-left: 180px;
}

.commands__nav-title {
  margin-left: 20px;
}

.rules_page{
  &.container__wrap-full-width{
    padding-left: 0;
    padding-top: 0;
  }
}