.map {
  height: 360px;
}

.map__marker-label-content {
  font-size: 12px;
  width: 130px;
  min-height: 40px;
  position: relative;
}

