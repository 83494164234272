.themedgraphs {
  .themedgraphs__chart-legend {
    padding: 0;
    list-style: none;

    span {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      @include directify($directions) {
        #{directed('margin-right')}: 10px;
      }
      // didn't rtl
      margin-right: 10px;
    }

    li {
      margin-top: 5px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }
}