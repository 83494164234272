.table__collapse-content--notification {
  top: 40px;
}

.tabs__notification {
  position: relative;
  min-height: 400px;
}

.button__notification-mark-all-as-read {
  position: absolute;
  right: 5px;
  top: 25px;
  margin-right: 15px !important;
}


.div__notification-item {
  height: 40px;
}

.span__notification-source {
  color: #4ce1b6;
}

.container__notification {
  width: 80%;

  .collapse__title {
    padding: 10px 10px 10px 5px !important;
  }

  .collapse__content {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .custom_card__container {
    padding-left: 16px !important;
  }
}

.topbar__btn-new-label-notification {
  right: auto !important;
  top: 6px !important;
  left: 0;
}

.span__notification-message {
  margin-left: 10px;
}

.col__notification-source-btn {
  padding-right: 8px !important;
}

.row__notification-content {
  margin-left: 0;
  margin-right: 0;

  .row {
    margin: 0;
    width: 100%;
  }

  [class*="col-"] {
    margin: 10px 0;
  }
}

.h5__notification-content-bdg {
  width: 70px;
  float: right;
}

.row__notification-content-tag {
  margin: 20px 0 !important;
}

.col__notification-rules {
  display: flex;
  flex-flow: column;
  height: 80%;
  justify-content: space-between !important;
}


.btn__notification-content {
  float: right;
  padding: 0 10px;
  height: 25px;

  svg {
    margin: 0 !important;
  }
}


.div__notification-resource-monitor-content {
  padding: 16px;
}